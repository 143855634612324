/* eslint-disable import/no-unresolved */
import { FC, useEffect } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { useCheckoutContext } from '../../contexts/checkout.context';
import moment from 'moment';

interface ShippingDateProps {
  value: any;
  onChange: (value: any) => void;
  unavailabledDates: any[];
}

export const ShippingDate: FC<ShippingDateProps> = ({ value, onChange, unavailabledDates = [] }) => {
  const inputId = 'shipping-date-input';
  const [{ beyondTodayDate }] = useCheckoutContext();
  const minDate = moment().toDate();
  const maxDate = moment().add(beyondTodayDate, 'days').toDate();

  useEffect(() => {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    if (inputElement) {
      const preventTyping = (e: KeyboardEvent) => e.preventDefault();

      inputElement.addEventListener('keydown', preventTyping);
      inputElement.addEventListener('input', preventTyping);

      return () => {
        inputElement.removeEventListener('keydown', preventTyping);
        inputElement.removeEventListener('input', preventTyping);
      };
    }
  }, []);

  return (
    <div className="flex flex-col space-y-2">
      <div>Selected Shipping Date</div>
      <Datepicker
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        disabledDates={unavailabledDates}
        displayFormat={'YYYY-MM-DD'}
        primaryColor="red"
        asSingle={true}
        useRange={false}
        readOnly
        onChange={(val) => {
          onChange(val);
        }}
        inputId={inputId}
      />
    </div>
  );
};
